import React, { useRef, useState, useEffect } from 'react';
import ProductCard from './ProductCard/ProductCard';
import './ProductList.css';

const ProductList = ({ scene, viewMode = 'overview' }) => {
    const productListRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [scrollPos, setScrollPos] = useState({ left: 0, top: 0 });

    useEffect(() => {
        const productListElement = productListRef.current;
        let frame;

        const onDragStart = (e) => {
            setIsDragging(true);
            setStartPos({
                x: e.pageX - productListElement.offsetLeft,
                y: e.pageY - productListElement.offsetTop
            });
            setScrollPos({
                left: productListElement.scrollLeft,
                top: productListElement.scrollTop
            });
        };

        const onDragEnd = () => {
            setIsDragging(false);
            window.cancelAnimationFrame(frame);
        };

        const onDragMove = (e) => {
            if (!isDragging) return;
            e.preventDefault();
            const newX = e.pageX - productListElement.offsetLeft;
            const newY = e.pageY - productListElement.offsetTop;
            const walkX = (newX - startPos.x) * 1.0;
            const walkY = (newY - startPos.y) * 1.0;

            frame = window.requestAnimationFrame(() => {
                productListElement.scrollLeft = scrollPos.left - walkX;
                productListElement.scrollTop = scrollPos.top - walkY;
            });
        };

        productListElement.addEventListener('mousedown', onDragStart);
        productListElement.addEventListener('mouseleave', onDragEnd);
        productListElement.addEventListener('mouseup', onDragEnd);
        productListElement.addEventListener('mousemove', onDragMove);

        return () => {
            window.cancelAnimationFrame(frame);
            productListElement.removeEventListener('mousedown', onDragStart);
            productListElement.removeEventListener('mouseleave', onDragEnd);
            productListElement.removeEventListener('mouseup', onDragEnd);
            productListElement.removeEventListener('mousemove', onDragMove);
        };
    }, [isDragging, startPos, scrollPos]);

    const numRows = viewMode === 'detail' ? 3 : 1; // 3 Zeilen in Detailansicht, 1 Zeile in Übersicht

    // Überprüfen, ob scene.products existiert und ein Array ist
    const productsInRows = scene && Array.isArray(scene.products) 
        ? scene.products.reduce((acc, product, index) => {
            const rowIndex = Math.floor(index / numRows);
            if (!acc[rowIndex]) {
                acc[rowIndex] = [];
            }
            acc[rowIndex].push(product);
            return acc;
        }, [])
        : [];

    const containerClass = `product-list-container ${viewMode}`;
    const listClass = `product-list ${viewMode === 'detail' ? 'no-scrollbar' : ''}`;

    return (
        <div className={containerClass}>
            <p className={`product-list-header ${viewMode === 'detail' ? 'detail' : ''}`}>Produkte im Set</p>
            <div className={listClass} ref={productListRef}>
                {productsInRows.map((row, rowIndex) => (
                    <div key={rowIndex} className="product-row">
                        {row.map(product => (
                            <ProductCard key={product.product_id} product={product} viewMode={viewMode} />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductList;
