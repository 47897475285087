import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, SHOPS_ENDPOINT } from '../../apiConfig';
import './CreateShop.css';

const CreateShop = ({ onShopCreated }) => {
  const [shopData, setShopData] = useState({
    name: "",
    description: "",
   // backgroundImage: null,
   // productType: ""
  });

  const handleInputChange = (e) => {
    setShopData({ ...shopData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setShopData({ ...shopData, backgroundImage: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_BASE_URL}${SHOPS_ENDPOINT}`, shopData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      onShopCreated();
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Error creating the shop.');
    }
  };

  return (
    <div className="create-shop-container">
      <div className="create-shop-form">
        <form onSubmit={handleSubmit}>
          <h2>Create New Shop</h2>
          <input 
            type="text" 
            name="name" 
            placeholder="Shop Name" 
            onChange={handleInputChange}
            required
          />
          <textarea 
            name="description" 
            placeholder="Shop Description" 
            onChange={handleInputChange} 
            required
          />
          <label htmlFor="backgroundImage">Select Background Image:</label>
          <input 
            type="file" 
            name="backgroundImage" 
            id="backgroundImage" 
            onChange={handleFileChange} 
          />
          <label htmlFor="productType">Choose Product Type:</label>
          <select 
            name="productType" 
            id="productType" 
            onChange={handleInputChange}
          >
            <option value="">Select an option</option>
            {/* Insert product type options here */}
          </select>
          <button type="submit">Create Shop</button>
        </form>
      </div>
    </div>
  );
};

export default CreateShop;
