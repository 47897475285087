import React, { useState } from 'react';
import { useMultistepForm } from '../Form/useMultistepForm';
import FileUploadSection from '../FileUploadSection'; // Angenommen, Sie haben diese Komponente
import Details from '../Details'; // Angenommen, Sie haben diese Komponente
import SceneSelection from '../SceneSelection';
import axios from 'axios';
import { API_BASE_URL, PRODUCTS_ENDPOINT, PRODUCTS_SCENE_ENDPOINT } from '../../../../apiConfig'; // Pfade entsprechend anpassen

const INITIAL_DATA = {
    title: "",
    description: "",
    scene: null
}

const ProductForm = ({ shopData, handleCancel, handleFinish }) => {
  const [data, setData] = useState(INITIAL_DATA);

  function updateFields(fields) {
    setData(prev => {
      return { ...prev, ...fields };
    });
  }

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next
  } = useMultistepForm([
    <SceneSelection shopData={shopData} updateFields={updateFields} />,
    <FileUploadSection />,
    <Details {...data} updateFields={updateFields} />
  ], handleCancel);

  async function onSubmit(e) {
    e.preventDefault();
    if (!isLastStep) return next();
    const token = localStorage.getItem('token');
    try {
      // Create a product
      const productResponse = await axios.post(`${API_BASE_URL}${PRODUCTS_ENDPOINT}`, data, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      console.log('Product created:', productResponse.data);
      const product_id = productResponse.data.id;
      
      await axios.post(`${API_BASE_URL}${PRODUCTS_SCENE_ENDPOINT}`, {
        scene_id: data.scene,
        product_id: product_id
    }, {
        headers: { 'Authorization': `Bearer ${token}` }
    });
      // Link the product to a scene
     
    
      console.log('Product linked to the shop');
      handleFinish();
    } catch (error) {
      console.error('Error while sending data:', error);
    }
    handleFinish();
  }

  return (
    <div style={{
      position: "relative",
      background: "white",
      border: "1px solid black",
      padding: "2rem",
      margin: "1rem",
      borderRadius: ".5rem",
      fontFamily: "Arial",
    }}>
      <form onSubmit={onSubmit}>
        <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
          {currentStepIndex + 1} / {steps.length}
        </div>
        {step}
        <div style={{ marginTop: "1rem", display: "flex", gap: ".5rem", justifyContent: "flex-end" }}>
          <button type="button" onClick={back}>
            {isFirstStep ? "Cancel" : "Back"}
          </button>
          <button type="submit">
            {isLastStep ? "Finish" : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
