import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import "./RegisterLogin.css";
import { Link, useNavigate} from "react-router-dom";
import { UserAuth } from '../../context/AuthContext';

function SignUp() {
    const { createUser } = UserAuth();
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const validatePassword = () => {
        return password.length >= 8 && password === passwordConfirm;
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setError('');
        if (!validatePassword()) {
            setError('Password validation failed');
            return;
        }
        try {
            await createUser(username, email, password);
            //navigate to Validate Email Page
            navigate('/verify-email');
        } catch (error) {
            setError(error.response.data.message || "An error occurred");
            console.error("Registration Error:", error);
        }
    }

    return (
        <div className="register-page">
            <form className="form" onSubmit={submitHandler}>
                <h2>Sign Up</h2>
                {error && <p className="error">{error}</p>}
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input type="text" className="form-control" id="username" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="email">E-Mail</label>
                    <input type="text" className="form-control" id="email" placeholder="Enter E-Mail" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="passwordConfirm">Confirm Password</label>
                    <input type="password" className="form-control" id="passwordConfirm" placeholder="Confirm Password" onChange={(e) => setPasswordConfirm(e.target.value)} />
                </div>
                <Button variant="primary" type="submit">Sign Up</Button>
                <p className="register-link">Already have an account?{' '}
                    <Link to="/Login" className='underline'>
                        Login here
                    </Link>.
                </p>
            </form>
        </div>
    )
}

export default SignUp;
