// ShopPage.js
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext'; // Pfad anpassen
import ShopOverview from '../components/Shop/ShopOverview/ShopOverview';

function ShopPage() {
  const { shopData, loading, error, loadShop } = useContext(ShopContext);
  const { shopName } = useParams();

  useEffect(() => {
    loadShop(shopName);
  }, [shopName, loadShop]);

  const currentShopData = shopData[shopName];

  if (loading) {
    return <p>Lädt...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (currentShopData) {
    return <ShopOverview data={currentShopData} />;
  }

  return <p>Keine Daten verfügbar.</p>;
}

export default ShopPage;
