import React from 'react';
import './ShopOverview.css'; // Stellen Sie sicher, dass Sie die entsprechenden CSS-Styles haben
import Scene from './SceneContainer/Scene/Scene';
import HeaderContainer from '../HeaderContainer/HeaderContainer'
import Footer from '../Footer/Footer';
import ScenesContainer from './SceneContainer/ScenesContainer';

const ShopOverview = ({ data }) => {
  // Konvertiert CSS-String in ein Objekt
  const parseStyleString = (styleString) => {
    return styleString
      .split(';')
      .filter(style => style.split(':')[0] && style.split(':')[1])
      .map(style => style.split(':'))
      .reduce((styleObj, style) => {
        const key = style[0].trim().replace(/-./g, c => c.substr(1).toUpperCase()); // Konvertiert z.B. 'background-color' zu 'backgroundColor'
        const value = style[1].trim();
        styleObj[key] = value;
        return styleObj;
      }, {});
  };

  const bgStyle = parseStyleString(data.bg_style);

  return (
    <div className="shop-page" style={bgStyle}>
      <HeaderContainer settings={data.settings} />
      <ScenesContainer data={data} />
      <Footer />
    </div>
  );
};

export default ShopOverview;
