import React, { useState, useEffect } from 'react';
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, SHOPS_ENDPOINT } from '../apiConfig';
import axios from 'axios';
import Dashboard from "../components/Dashboard/Dashboard";
import CreateShop from '../components/InitialSetup/CreateShop'; // Stellen Sie sicher, dass dieser Pfad korrekt ist

const DashboardPage = () => {
    const { user, logout } = UserAuth();
    const navigate = useNavigate();
    const [shops, setShops] = useState(null);
    const [isShopCreated, setIsShopCreated] = useState(false);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
            console.log('You are logged out');
        } catch (e) {
            console.log(e.message);
        }
    };

    const checkIfShopCreated = async () => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher holen
        if (!token) {
            console.log('Kein Token gefunden');
            return;
        }

        try {
            const response = await axios.get(`${API_BASE_URL}${SHOPS_ENDPOINT}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const shopsData = response.data;
            setShops(shopsData); // Speichern der Shop-Daten im Zustand
            localStorage.setItem('shopId', shopsData.id);
            setIsShopCreated(!!shopsData.id);
        } catch (error) {
            console.error('Fehler beim Abrufen der Shop-Daten:', error);
        }
    };

    useEffect(() => {
        checkIfShopCreated();
    }, []);

    return (
        <div>
            {!isShopCreated ? (
                <CreateShop onShopCreated={() => checkIfShopCreated()} />
            ) : (
                <>
                    <h2>Welcome to the dashboard!</h2>
                    <p>Email: {user && user.email}</p>
                    <button onClick={handleLogout}>Logout</button>
                    <Dashboard email={user.email} shop={shops}/>
                </>
            )}
        </div>
    );
};

export default DashboardPage;
