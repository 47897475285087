import React from 'react';

function About() {
    return (
        <div >
            <h1>About Page</h1>
        </div>

    )
}

export default About;