import React, { useState } from 'react';
import styled from 'styled-components';

const EditorWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const StageList = styled.div`
  margin-top: 20px;
`;

const StageItem = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const StageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StageTitle = styled.h2`
  font-size: 20px;
  margin: 0;
`;

const DeleteStageButton = styled.button`
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d43f00;
  }
`;

const ProductList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ProductItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ProductImageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ProductImageItem = styled.li`
  margin-top: 10px;
`;

const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const DeleteButton = styled.button`
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d43f00;
  }
`;

const AddImageButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Creator = () => {
  const [stageTitle, setStageTitle] = useState('');
  const [stageDescription, setStageDescription] = useState('');
  const [stages, setStages] = useState([]);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [products, setProducts] = useState([]);

  const handleStageTitleChange = (e) => {
    setStageTitle(e.target.value);
  };

  const handleStageDescriptionChange = (e) => {
    setStageDescription(e.target.value);
  };

  const handleStageAdd = () => {
    if (stageTitle.trim() !== '') {
      setStages([
        ...stages,
        { title: stageTitle, description: stageDescription, products: [] },
      ]);
      setStageTitle('');
      setStageDescription('');
    }
  };

  const handleStageDelete = (index) => {
    const updatedStages = [...stages];
    updatedStages.splice(index, 1);
    setStages(updatedStages);
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleProductDescriptionChange = (e) => {
    setProductDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProductImages([...productImages, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProductAdd = () => {
    if (productName.trim() !== '') {
      setProducts([
        ...products,
        {
          name: productName,
          description: productDescription,
          images: productImages,
        },
      ]);
      setProductName('');
      setProductDescription('');
      setProductImages([]);
    }
  };

  const handleProductDelete = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleProductAssign = (stageIndex) => {
    if (stages[stageIndex].products.length < 50) {
      const updatedStages = [...stages];
      const productToAdd = {
        name: productName,
        description: productDescription,
        images: productImages,
      };
      updatedStages[stageIndex].products.push(productToAdd);
      setStages(updatedStages);
      setProductName('');
      setProductDescription('');
      setProductImages([]);
    }
  };

  return (
    <EditorWrapper>
      <Title>Editor</Title>
      <Form>
        <FormGroup>
          <Label>Stage Title:</Label>
          <Input
            type="text"
            placeholder="Stage Title"
            onChange={handleStageTitleChange}
            value={stageTitle}
          />
        </FormGroup>
        <FormGroup>
          <Label>Stage Description:</Label>
          <TextArea
            placeholder="Stage Description"
            onChange={handleStageDescriptionChange}
            value={stageDescription}
          />
        </FormGroup>
        <Button type="button" onClick={handleStageAdd}>
          Add Stage
        </Button>
      </Form>
      <StageList>
        {stages.map((stage, stageIndex) => (
          <StageItem key={stageIndex}>
            <StageHeader>
              <StageTitle>{stage.title}</StageTitle>
              <DeleteStageButton onClick={() => handleStageDelete(stageIndex)}>
                Delete Stage
              </DeleteStageButton>
            </StageHeader>
            <p>{stage.description}</p>
            <h3>Products:</h3>
            {stage.products.map((product, productIndex) => (
              <ProductItem key={productIndex}>
                <h4>{product.name}</h4>
                <p>{product.description}</p>
                <h5>Product Images:</h5>
                <ProductImageList>
                  {product.images.map((image, imageIndex) => (
                    <ProductImageItem key={imageIndex}>
                      <ProductImage src={image} alt={`Product ${productIndex + 1}`} />
                    </ProductImageItem>
                  ))}
                </ProductImageList>
                <DeleteButton onClick={() => handleProductDelete(productIndex)}>
                  Delete Product
                </DeleteButton>
              </ProductItem>
            ))}
            <FormGroup>
              <Label>Product Name:</Label>
              <Input
                type="text"
                placeholder="Product Name"
                onChange={handleProductNameChange}
                value={productName}
              />
            </FormGroup>
            <FormGroup>
              <Label>Product Description:</Label>
              <TextArea
                placeholder="Product Description"
                onChange={handleProductDescriptionChange}
                value={productDescription}
              />
            </FormGroup>
            <FormGroup>
              <Label>Upload Product Images:</Label>
              <Input type="file" accept="image/*" multiple onChange={handleImageChange} />
              <ProductImageList>
                {productImages.map((image, imageIndex) => (
                  <ProductImageItem key={imageIndex}>
                    <ProductImage src={image} alt={`Product ${stageIndex + 1}`} />
                  </ProductImageItem>
                ))}
              </ProductImageList>
            </FormGroup>
            <Button type="button" onClick={() => handleProductAssign(stageIndex)}>
              Assign Product
            </Button>
          </StageItem>
        ))}
      </StageList>
    </EditorWrapper>
  );
};

export default Creator;
