// ShopContext.js
import React, { createContext, useState } from 'react';
import axios from 'axios';
import {API_BASE_URL, SHOPS_ENDPOINT} from '../apiConfig';

export const ShopContext = createContext();

export const ShopProvider = ({ children }) => {
  const [shopData, setShopData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadShop = async (shopName) => {
    // Prüfen, ob die Daten bereits geladen sind
    if (shopData[shopName]) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}${SHOPS_ENDPOINT}/${shopName}`);
      setShopData(prevData => ({...prevData, [shopName]: response.data}));
    } catch (err) {
      setError('Fehler beim Laden der Shop-Daten');
      console.error('Fehler:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ShopContext.Provider value={{ shopData, loading, error, loadShop }}>
      {children}
    </ShopContext.Provider>
  );
};
