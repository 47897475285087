import React, { useState} from 'react';
import SceneForm from './SceneForm/SceneForm'; // Stellen Sie sicher, dass Sie den korrekten Pfad angeben
import ProductForm from './ProductForm/ProductForm'; // Stellen Sie sicher, dass Sie den korrekten Pfad angeben


const Creator = ({shopData, reload}) => {
  const [selectedForm, setSelectedForm] = useState(null);

  const boxStyle = {
    position: "relative",
    background: "white",
    border: "1px solid black",
    padding: "2rem",
    margin: "1rem",
    borderRadius: ".5rem",
    fontFamily: "Arial",
    maxWidth: "max-content"
  };




  const handleCancel = () => {
    const confirmCancel = window.confirm("Do you really want to Cancel?");
  
    if (confirmCancel) 
      setSelectedForm(null);
  }

  const handleFinish = () => {
      setSelectedForm(null);
      reload();
  }

  return (
    <div style={boxStyle}>
      {!selectedForm && (
        <div>
          <button onClick={() => setSelectedForm('product')} >
            Produkt erstellen
          </button>
          <button onClick={() => setSelectedForm('scene')} >
            Szene erstellen
          </button>
        </div>
      )}

      {selectedForm === 'product' && <ProductForm shopData={shopData} handleCancel={handleCancel} handleFinish={handleFinish}/>}
      {selectedForm === 'scene' && <SceneForm handleCancel={handleCancel} handleFinish={handleFinish}/>}
    </div>
  );
};

export default Creator;
