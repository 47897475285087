import React, { useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { UserAuth } from '../../context/AuthContext';
import './VerifyEmail.css';

const VerifyEmail = () => {
    const { verifyMail } = UserAuth();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { token, email: encodedEmail } = useParams();

    const sendVerificationEmail = async (email) => {
        try {
            await verifyMail(email);
            setMessage('E-Mail verschickt! Bitte überprüfen Sie Ihre E-Mail-Adresse zur Bestätigung.');
        } catch (error) {
            setMessage('Fehler beim Versenden der Verifizierungs-E-Mail.');
            console.error(error);
        }
    };


    useEffect(() => {

        
        const verifyEmail = async () => {
            // Prüfen, ob die Komponente mit Token und E-Mail-Parametern aufgerufen wurde
            if (token && encodedEmail) {
                const email = decodeURIComponent(encodedEmail);
                try {
                    // Senden einer Anfrage zur Token-Überprüfung
                    await axios.post('http://localhost:3002/api/users/verify-token', { token, email });
                    setMessage('E-Mail erfolgreich verifiziert. Sie werden zum Dashboard weitergeleitet.');
                    navigate('/login'); // Weiterleitung zum Dashboard
                } catch (error) {
                    setMessage('Fehler bei der Verifizierung der E-Mail.');
                    console.error('Verification Error:', error);
                }
            } else {
                // Versenden der Verifizierungs-E-Mail
                const storedEmail = localStorage.getItem('email');
                if (storedEmail) {
                    sendVerificationEmail(storedEmail);
                } else {
                    setMessage('Keine E-Mail-Adresse zum Verifizieren gefunden.');
                }
            }
        };

        verifyEmail();
    }, [token, encodedEmail, verifyMail, navigate, sendVerificationEmail]);

    return (
        <div className="email-verification-container">
            <div className="email-animation">

            </div>
            <div className="email-message">
                <p>{message}</p>
            </div>
        </div>
    );
};

export default VerifyEmail;
