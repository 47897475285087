import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL, LOGIN_ENDPOINT, REGISTER_ENDPOINT, USERS_ENDPOINT } from '../apiConfig';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Funktion zum Laden der Benutzerdaten
    const loadUser = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.get(`${API_BASE_URL}/users/profile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUser(response.data); // Setze den Benutzer mit den zurückgegebenen Daten
            } catch (error) {
                console.error('AuthContext loadUser error:', error);
                localStorage.removeItem('token'); // Bei einem Fehler, entferne das Token
                setUser(null);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        loadUser();
    }, []);

    const createUser = async (username, email, password) => {
        try {
            const response = await axios.post(`${API_BASE_URL}${REGISTER_ENDPOINT}`, {
                username,
                email,
                password
            });
            console.log(response.data.message);
            localStorage.setItem('email', email);
            return response;
        } catch (error) {
            console.error('AuthContext createUser error:', error.response ? error.response.data.message : error.message);
            throw error;
        }
    };

    const signIn = async (email, password) => {
        try {
            const response = await axios.post(`${API_BASE_URL}${LOGIN_ENDPOINT}`, {
                email,
                password
            });
            console.log("Hi");
            const { token } = response.data;
            localStorage.setItem('token', token);
            await loadUser(); // Lade den Benutzer nach erfolgreichem Login
            return response;
        } catch (error) {
            console.error('AuthContext signIn error:', error.response ? error.response.data.message : error.message);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('shopId');
        setUser(null);
    };

    const verifyMail = async (email) => {
        try {
            await axios.post(`${API_BASE_URL}${USERS_ENDPOINT}/verify`,{ email });
        } catch (error) {
            console.error('AuthContext verifyMail error:', error);
            throw error;
        }
    };

    return (
        <UserContext.Provider value={{ user, loading, createUser, signIn, logout, verifyMail }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};
