import React, { useState } from 'react';
import { FormWrapper } from './Form/FormWrapper';

const Details = ({name, description, updateFields}) => {
  return (
    <FormWrapper title="Scene Details">
     <label>Titel *</label>
     <input autoFocus required type="text" value={name} onChange={e => updateFields({ name: e.target.value})}/>
     <label>Beschreibung</label>
     <textarea type="text" value={description} onChange={e => updateFields({ description: e.target.value})}/>
    </FormWrapper>
  );
};

export default Details;
