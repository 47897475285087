import React from 'react';
import Footer from '../Footer/Footer';
import './SceneDetail.css';
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import ProductList from '../ShopOverview/SceneContainer/Scene/ProductList/ProductList';
import BackButton from '../../Button/BackButton';

const SceneDetail = ({ scene }) => {
  return (
    <>
    <HeaderContainer settings = {'hi'}/>
    <BackButton/>
    <div className="scene-detail-container">
      <div className="content-container">
        <div className="scene-image-container">
          {/* Hier würde das große Szenenbild eingefügt. */}
          <img src={'/Arbeitsplatz.jpeg'} alt={'Arbeitsplatz'} className="scene-image"/>
        </div>
        <div className="scene-info-container">
          {/* Titel und Beschreibung der Szene. */}
          <h1 className="scene-detail-title">{scene.scene_name}</h1>
          <p className="scene-detail-description">{scene.scene_description}</p>
          
          {/* Produkte, die in der Szene verwendet werden. */}
          <div className="scene-products">
              <ProductList scene={scene} viewMode='detail'/>
          
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default SceneDetail;
