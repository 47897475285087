import React from 'react';
import TryForFreeButton from '../components/TryForFree/TryForFree';
import Home_Header from '../sections/home-header';
import Banner1 from '../sections/home-banner-1';
import Hero from '../components/Hero/Hero';

function Home() {
    return (

        <div>
            <Home_Header/>
            <Banner1/>

            <Hero
                title="Share your Linktree from your Instagram, TikTok, Twitter and other bios"
                description="Add your unique Linktree URL to all the platforms and places you find your audience. Then use your QR code to drive your offline traffic online."
                buttonText="Get started for free"
                buttonColor="#FFFFFF" // Example button color
                backgroundColor="#B23A48" // Example background color
                textColor="#FFFFFF" // Example text color
            />
            <Hero
                title="Share your Linktree from your Instagram, TikTok, Twitter and other bios"
                description="Add your unique Linktree URL to all the platforms and places you find your audience. Then use your QR code to drive your offline traffic online."
                buttonText="Get started for free"
                buttonColor="#FFFFFF" // Example button color
                backgroundColor="#B23A48" // Example background color
                textColor="#FFFFFF" // Example text color
            />
        </div>

    )
}
export default Home;