import React, { useState, useRef, useEffect } from 'react';
import { FaPlus, FaChartBar, FaCog, FaStore } from 'react-icons/fa'; // Import the icons
import './TabBar.css'; // Ensure this path is correct

const TabBar = ({ activeTab, changeTab }) => {
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabBarRef = useRef();
  const tabRefs = {
    creator: useRef(),
    manager: useRef(),
    analytics: useRef(),
    settings: useRef(),
  };

  const tabs = [
    { name: 'creator', icon: <FaPlus />, tooltip: 'Creator' },
    { name: 'manager', icon: <FaStore />, tooltip: 'Manager' },
    { name: 'analytics', icon: <FaChartBar />, tooltip: 'Analytics' },
    { name: 'settings', icon: <FaCog />, tooltip: 'Settings' },
  ];

  useEffect(() => {
    const activeTabRef = tabRefs[activeTab].current;
    const tabBar = tabBarRef.current;
    if (activeTabRef && tabBar) {
      const { offsetLeft, offsetWidth } = activeTabRef;
      setIndicatorStyle({
        left: offsetLeft + tabBar.scrollLeft,
        width: offsetWidth,
      });
    }
  }, [activeTab]);

  return (
    <div className="tab-bar" ref={tabBarRef} style={{ width: '500px', position: 'relative' }}>
      <div className="indicator" style={indicatorStyle} />
      {tabs.map((tab) => (
        <div
          key={tab.name}
          ref={tabRefs[tab.name]}
          className={`tab-item ${activeTab === tab.name ? 'active' : ''}`}
          onClick={() => changeTab(tab.name)}
          title={tab.tooltip} // Tooltip text
        >
          <div className="icon">{tab.icon}</div>
        </div>
      ))}
    </div>
  );
};

export default TabBar;
