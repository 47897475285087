const API_BASE_URL = 'http://localhost:3002/api';
const LOGIN_ENDPOINT = '/users/login';
const REGISTER_ENDPOINT = '/users/register';
const SHOPS_ENDPOINT = '/shops';
const SCENES_ENDPOINT = '/scenes';
const SCENES_SHOP_ENDPOINT = '/sceneshop';
const PRODUCTS_SCENE_ENDPOINT = '/productscene';
const PRODUCTS_ENDPOINT = '/products';
const USERS_ENDPOINT = '/users'

export { API_BASE_URL, LOGIN_ENDPOINT, REGISTER_ENDPOINT, SHOPS_ENDPOINT, USERS_ENDPOINT, SCENES_ENDPOINT, PRODUCTS_ENDPOINT,
SCENES_SHOP_ENDPOINT, PRODUCTS_SCENE_ENDPOINT};
