import React from 'react';
import './home-banner-1.css'; // Assume your CSS is defined here

const Banner1 = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h2>Get more with Linktree</h2>
        <p>Help your followers discover, enjoy and support everything you have to offer when you add Linktree’s ‘Link In Bio’ to your Instagram today.</p>
        <button className="banner-button">Sign up for free now</button>
      </div>
    </div>
  );
}

export default Banner1;
