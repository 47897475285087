import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext';
import SceneDetail from '../components/Shop/SceneDetail/SceneDetail';

function SceneDetailPage() {
  const { shopData, loadShop } = useContext(ShopContext);
  const [scene, setScene] = useState(null);
  const { shopName, sceneId } = useParams();

  // Lade den Shop
  useEffect(() => {
    loadShop(shopName);
  }, [shopName, loadShop]);

  // Suche die Szene, sobald die Shop-Daten geladen sind
  useEffect(() => {
    if (shopData[shopName] && shopData[shopName].scenes) {
      const foundScene = shopData[shopName].scenes.find(s => s.scene_id === sceneId);
      setScene(foundScene);
    }
  }, [shopData, shopName, sceneId]);

  if (!scene) {
    return <p>Lädt...</p>;
  } else {
    return <SceneDetail scene={scene} />;
  }
}

export default SceneDetailPage;
