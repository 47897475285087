import React from 'react';


function Test() {
    return(
        <h1>Testing</h1>
    );
}
function Pricing() {
    const myElement = <h1>React is {5 * 3} times better</h1>;
    return (
        <>
            <h1>{myElement}</h1>
            <h1>Pricing Page</h1>
            <Test />
        </>

    )
}

export default Pricing;