import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Scene.css';
import ProductCard from './ProductList/ProductCard/ProductCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SceneHeader from './SceneHeader/SceneHeader';
import ProductList from './ProductList/ProductList';

const Scene = ({ scene }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Funktion, um die URL zu ändern
  const redirectToScene = () => {
    // Annahme: URL ist im Format /shop_id
    // Extrahiere shop_id aus der aktuellen URL
    const pathParts = location.pathname.split('/').filter(part => part);
    const shopId = pathParts[0];

    // Navigiere zur neuen URL /shop_id/scene_id
    navigate(`/${shopId}/${scene.scene_id}`);
  };

  return (
    <div className="scene" onClick={redirectToScene}>
      <SceneHeader scene={scene}/>
      <div onClick={(e) => e.stopPropagation()}>
        <ProductList scene={scene}/>
      </div>
      <button className="show-details" onClick={redirectToScene}>
        Mehr Anzeigen <FontAwesomeIcon icon={faArrowRight}/>
      </button>
    </div>
  );
};

export default Scene;
