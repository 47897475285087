import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, SHOPS_ENDPOINT } from '../../../apiConfig'; // Stellen Sie sicher, dass der Pfad korrekt ist

const Manager = ({ shopData, reload }) => {
  const [openSceneId, setOpenSceneId] = useState(null); // Zustand, um zu verfolgen, welche Szene geöffnet ist

  const handleToggleScene = (id) => {
    setOpenSceneId(openSceneId === id ? null : id); // Szene umschalten
  };

  const handleDelete = async (name) => {
    //TODO
    try{
      reload(); // State aktualisieren oder Seite neu laden
    } catch (error) {
      console.error('Fehler beim Löschen des Shops:', error);
    }
  };

  return (
    <div style={{
        border: "1px solid black",
        padding: "20px",
        margin: "20px",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "black",
        fontFamily: "Arial, sans-serif",
        textAlign: "center"
      }}>
      <h2>Manager-Bereich</h2>
      <p>Hier können Sie Ihre Manager-Aufgaben verwalten.</p>
      
      {shopData.scenes && Array.isArray(shopData.scenes) ? (
        shopData.scenes.map((scene) => (
          <div key={scene.id}>
            <div 
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
              onClick={() => handleToggleScene(scene.id)}>
              {scene.name} - {scene.description}
            </div>
            {openSceneId === scene.id && (
              <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                {scene.products.map((product) => (
                  <li key={product.id}>
                    <div>{product.name} - {product.description}</div>
                    <button onClick={() => handleDelete(product.id)}>Produkt Löschen</button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))
      ) : (
        <p>Keine Szenen vorhanden.</p>
      )}
    </div>
  );
};

export default Manager;
