import React from 'react';
import './ProductCard.css';

const ProductCard = ({ product, viewMode }) => (
  <div className={`product-card ${viewMode === 'detail' ? 'detail' : ''}`}>
    <div className="image-container">
      <img src={product.image} alt={product.name} />
      <i className="fas fa-shopping-bag shopping-bag-icon"></i> 
    </div>
  </div>
);

export default ProductCard;
