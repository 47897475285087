import React from 'react';
import './SceneHeader.css';

const SceneHeader = ({ scene }) => (
  <div className="scene-header">
    <h2 className="scene-title">{scene.scene_name}</h2>
    <img src={scene.image} alt={scene.scene_name} className="scene-image" />
  </div>
);

export default SceneHeader;
//<p className="scene-description">{scene.description}</p>