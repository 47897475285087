
import React from "react";
import { Outlet } from "react-router-dom";
import NavbarC from "../components/NavBar/NavBar";
import './Layout.css'; // Stellen Sie sicher, dass der Pfad korrekt ist

const Layout = () => {
    return (
        <>
            <NavbarC/>
            <div className="main-content">
                <Outlet/>
            </div>
        </>
    )
}

export default Layout;