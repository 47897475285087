// HeaderContainer.js

import React from 'react';
import Header from './Header/Header'; // Stellen Sie sicher, dass der Pfad zu Ihrer Header-Komponente korrekt ist.
import './HeaderContainer.css'; // Importiert die CSS-Datei für Styling.

const HeaderContainer = ({ settings }) => {
  return (
    <div className="header-container">
      <Header settings={settings} />
    </div>
  );
};

export default HeaderContainer;
