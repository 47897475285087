// Home_Header.js
import React from 'react';
import './home-header.css';
import "./sections.css";
import ClaimShop from '../components/ClaimShop/ClaimShop';

const HomeHeader = () => {
  return (
    <div className = "container-wrapper" style={{ backgroundColor: 'purple' }}>
        <div className="home-header">
          <div className="header-content">
            <h1>The best link in bio tool for Instagram</h1>
            <p>Join the 40M+ creators, musicians, influencers, and brands using Linktree to share content and grow audiences with one simple link.</p>
            <button className="sign-up-button">Sign up for free now</button>
            <ClaimShop/>
          </div>
          <div className="header-image-container">
            <img src="./handshake.svg" alt="Descriptive Alt Text" />
          </div>
        </div>
    </div>
  );
};

export default HomeHeader;
