import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    const urlParts = window.location.pathname.split('/');
    urlParts.pop();
    const newUrl = urlParts.join('/');
    navigate(newUrl);
  };

  return (
    <button onClick={handleBackClick} style={backButtonStyle}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
};

const backButtonStyle = {
  backgroundColor: '#f0f0f0',
  color: '#333',
  border: 'none',
  borderRadius: '50%',
  padding: '15px 20px', // Größeres Padding für einen größeren Button
  margin: '10px',
  cursor: 'pointer',
  fontSize: '20px', // Größere Schriftgröße für das Icon
  position: 'absolute',
  left: '10px',
  top: '10px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  zIndex:1000
};

export default BackButton;
