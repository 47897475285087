import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({
  title,
  description,
  buttonText,
  buttonColor,
  backgroundColor,
  textColor,
  media
}) => {
  const sectionStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '20px'
  };

  const contentContainerStyle = {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const textContentStyle = {
    flex: 1,
    paddingRight: '20px', // Adjust the space between the text and media
  };

  const buttonStyle = {
    backgroundColor: buttonColor,
    color: textColor,
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '10px', // Added margin top for spacing after the description
  };

  const mediaStyle = {
    flex: 1,
  };

  return (
    <div style={sectionStyle}>
      <div style={contentContainerStyle}>
        <div style={textContentStyle}>
          <h1>{title}</h1>
          <p>{description}</p>
          <button style={buttonStyle}>{buttonText}</button>
        </div>
        <div style={mediaStyle}>
          {media}
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  media: PropTypes.node.isRequired, // This prop should be a JSX element representing the media content.
};

export default Hero;
