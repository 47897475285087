// ClaimShop.js
import React, { useState } from 'react';
import { API_BASE_URL, LOGIN_ENDPOINT, REGISTER_ENDPOINT } from '../../apiConfig';
import axios from 'axios';

const ClaimShop = () => {
  const [name, setName] = useState('');

  const handleSubmit = async () => {
    try {
      // Token aus dem Local Storage holen
      const token = localStorage.getItem('token');
  
      // Überprüfen, ob das Token existiert
      if (!token) {
        console.error('Kein Token gefunden');
        return;
      }
  
      // Axios Request mit Bearer Token
      const response = await axios.post(`${API_BASE_URL}/shops`, { name },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      console.log(response.data.message); // Zeigt die Antwort vom Server in der Konsole
    } catch (error) {
      console.error('Fehler beim Senden des Shop-Namens', error);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <input 
        type="text" 
        value={name} 
        onChange={(e) => setName(e.target.value)} 
        placeholder="Shop-Namen eingeben" 
      />
      <button onClick={handleSubmit}>Beanspruchen</button>
    </div>
  );
};

export default ClaimShop;
