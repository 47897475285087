import React, { useState, useEffect } from 'react';
import './Dashboard.css'; // Ensure this path is correct
import Settings from './Settings/Settings';
import TabBar from './TabBar/TabBar';
import Creator from './Creator/Creator';
import Manager from './Manager/Manager';
import { API_BASE_URL, SCENES_SHOP_ENDPOINT, PRODUCTS_SCENE_ENDPOINT } from '../../apiConfig';
import axios from 'axios';

const Dashboard = ({ email, shop }) => {
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState('settings'); // default to 'settings' or any tab you want
  const [shopData, setShopData] = useState(null);

  // Function to change the active tab from the child component
  const changeTab = (newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  const fetchShopData = async () => {
    try {
      const shop_id = shop.id; // Ersetzen Sie dies durch den tatsächlichen Shopnamen
      const scenesResponse = await axios.get(`${API_BASE_URL}${SCENES_SHOP_ENDPOINT}/${shop_id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const scenesWithProducts = await Promise.all(scenesResponse.data.map(async scene => {
        const productsResponse = await axios.get(`${API_BASE_URL}${PRODUCTS_SCENE_ENDPOINT}/${scene.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        return { ...scene, products: productsResponse.data };
      }));

      setShopData({ shop_id: shop_id, scenes: scenesWithProducts });
    } catch (error) {
      console.error('Fehler beim Abrufen von Szenen und Produkten:', error);
    }
  };


  const reload = async () => {
    fetchShopData();
  }

  useEffect(() => {

    fetchShopData();

  }, []);


  return (
    <>
      <TabBar activeTab={activeTab} changeTab={changeTab} />
      {activeTab === 'settings' && <Settings shopName = {shop.name} email={email} reload ={reload} />}
      {activeTab === 'creator' && <Creator shopData={shopData} reload={reload}/>}
      {activeTab === 'manager' && <Manager shopData={shopData} reload={reload}/>}
      {/* Add other components corresponding to other tabs here, conditionally rendered based on activeTab */}
    </>
  );
};

export default Dashboard;
