import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './RegisterLogin.css';
import { UserAuth } from "../../context/AuthContext";

function Login() {
    const { signIn } = UserAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
      
        try {
          const response = await signIn(email, password);
          if (response.data.isVerified) {
            navigate("/dashboard");
          } else {
            setError(response.data.message);
            navigate("/verify-email");
          }
        } catch (error) {
          if (error.response) {
            setError(error.response.data.message || "An error occurred");
          } else {
            setError("An error occurred. Please check your network and try again.");
          }
          console.error("Login Error:", error);
        }
    };
      

    return (
        <div className="login-page">
            <form className="form" onSubmit={handleSubmit}>
                <h2>Welcome Back!</h2>
                {error && <p className="error">{error}</p>}
                <div className="form-group">
                    <label htmlFor="email">Email address:</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
                <p className="register-link">If not registered,{' '}
                    <Link to="/SignUp" className='underline'>
                        Sign up here
                    </Link>.
                </p>
            </form>
        </div>
    );
}

export default Login;
