import React, { useState } from 'react';
import './Settings.css'; // Stellen Sie sicher, dass dieser Pfad korrekt ist

const Settings = ({ shopName, email, reload }) => {
  const [emailInput, setEmailInput] = useState(email);

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleShopNameChange = (e) => {
    reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logik zum Aktualisieren von E-Mail und Shop-Name
    console.log('Settings updated:', emailInput, shopName);
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>
      <form onSubmit={handleSubmit} className="settings-form">
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          id="email" 
          value={emailInput} 
          onChange={handleEmailChange} 
        />

        <label htmlFor="shop-name">Shop Name</label>
        <input 
          type="text" 
          id="shop-name" 
          value={shopName} 
          onChange={handleShopNameChange} 
        />

        <button type="submit">Update Settings</button>
      </form>
    </div>
  );
};

export default Settings;
