import React, { useState } from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faStore } from '@fortawesome/free-solid-svg-icons';

const Header = ({ settings }) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const toggleOverlay = () => setShowOverlay(!showOverlay);

    // Überprüfung, ob `settings` existiert und `shopImage` nicht null ist
    const shopImage = settings && settings.shopImage;

    return (
        <header className="shop-header">
            {shopImage ? (
                <img src={shopImage} alt="Shop" className="shop-image" />
            ) : (
                // Anzeigen des Platzhalter-Icons, wenn kein shopImage vorhanden ist
                <div className="shop-image-placeholder">
                    <FontAwesomeIcon icon={faStore} size="3x" />
                </div>
            )}
            <div className="title-container">
                <h1>{settings ? settings.shopName : 'Shop Name'}</h1>
            </div>
            <button className='link-button' onClick={toggleOverlay}>
                <FontAwesomeIcon icon={faLink} />
            </button>
            {showOverlay && (
                <div className="overlay">
                    <div className="close-button" onClick={toggleOverlay}>
                        &#10005; {/* Unicode für das X-Zeichen */}
                    </div>
                    {/* Inhalt des Overlays */}
                </div>
            )}
        </header>
    );
};

export default Header;
