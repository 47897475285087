import React, { useState } from 'react';

const SceneSelection = ({ shopData, updateFields }) => {
  const [selectedScene, setSelectedScene] = useState("");

  // Diese Funktion wird aufgerufen, wenn der Benutzer eine Szene auswählt.
  const handleSceneChange = (e) => {
    const sceneId = e.target.value;
    setSelectedScene(sceneId);
    updateFields({ scene: sceneId }); // Aktualisiert die Szene im Zustand des Elternformulars.
  };

  // Sicherstellen, dass Szenen ein Array ist.
  const scenes = shopData.scenes || [];

  return (
    <div>
      <h3>Select a Scene</h3>
      {scenes.length > 0 ? (
        scenes.map((scene) => (
          <label key={scene.id}>
            <input
              type="radio"
              name="scene"
              value={scene.id}
              checked={selectedScene === scene.id}
              onChange={handleSceneChange} // Die Szene wird jetzt direkt bei Änderung aktualisiert.
            />
            {scene.name}
          </label>
        ))
      ) : (
        <p>No scenes available to display.</p>
      )}
    </div>
  );
};

export default SceneSelection;
