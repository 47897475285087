import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './styles.css'; // Ensure this path is correct

const NavbarC = () => {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Navbar.Brand href="/">
        <img src="/logo.png" alt="Logo" width="50" height="50" />
        SETUP
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/features">Features</Nav.Link>
          <Nav.Link href="/discover">Discover</Nav.Link>
          <Nav.Link href="/pricing">Pricing</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="/login">Log in</Nav.Link>
          <Nav.Link href="/signup">Sign up</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarC;
