import React from 'react';
import './ScenesContainer.css';
import Scene from './Scene/Scene'; // Angenommen, die Scene-Komponente ist in der Datei Scene.js definiert

// Die ScenesContainer-Komponente nimmt `data` als Prop an
const ScenesContainer = ({ data }) => {
  return (
    <div className="scenes-container">
      {data.scenes.map(scene => (
        <Scene key={scene.scene_id} scene={scene} />
      ))}
    </div>
  );
};

export default ScenesContainer;