import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Login from "./pages/RegisterLogin/Login";
import SignUp from "./pages/RegisterLogin/SignUp";
import Pricing from "./pages/Pricing";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import DashboardPage from "./pages/DashboardPage";
import ShopPage from './pages/ShopPage'; // Importieren Sie ShopPage
import SceneDetailPage from './pages/SceneDetailPage';
import { AuthContextProvider } from "./context/AuthContext";
import { ShopProvider } from './context/ShopContext';
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css"
import Creator from './pages/Creator';
import VerifyEmail from './pages/RegisterLogin/VerifyEmail';

const AppWrapper = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<SignUp/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/faq" element={<FAQ/>}/>
          </Route>
          <Route path="/dashboard" element={
              <ProtectedRoute>
                <DashboardPage/>
              </ProtectedRoute>}/>
          <Route path=":shopName/:sceneId" element={<SceneDetailPage/>} />
          <Route path=":shopName" element={<ShopPage/>} />
          <Route path="/create" element={<Creator/>} />
          <Route path="/verify-email" element={<VerifyEmail/>}/>
          <Route path="/verify-email/:token/:email" element={<VerifyEmail/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const App = () => {
  return (
    <AuthContextProvider>
      <ShopProvider>
        <AppWrapper /> 
      </ShopProvider>
    </AuthContextProvider>
  );
};

export default App;
