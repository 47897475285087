import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';
import './Creator.css';

const FileUploadSection = ({ onFilesAccepted }) => {
  const onDrop = useCallback(acceptedFiles => {
    onFilesAccepted(acceptedFiles);
  }, [onFilesAccepted]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: 8
  });

  return (
    <section className="upload-section">
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <div className="drop-message">
            <p>Drag 'n' drop some files here, or click to select files (Max 8)</p>
            <FaUpload className="upload-icon" />
          </div>
        )}
      </div>
      <aside className="file-list-section">
        <ul>
          {acceptedFiles.map(file => (
            <li key={file.path}>
              {file.path} - {file.size} bytes
            </li>
          ))}
        </ul>
      </aside>
    </section>
  );
};

export default FileUploadSection;
