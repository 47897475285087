import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { user, loading } = UserAuth();

    if (loading) {
        return <div>Loading...</div>; // oder eine andere Ladeanzeige Ihrer Wahl
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;
