import React, { useState , FormEvent} from 'react';
import { useMultistepForm } from '../Form/useMultistepForm';
import FileUploadSection from '../FileUploadSection';
import Details from '../Details';
import axios from 'axios';
import {API_BASE_URL, SCENES_ENDPOINT, SCENES_SHOP_ENDPOINT} from '../../../../apiConfig';

const INITIAL_DATA = {
    name: "",
    description: "",
}

const SceneForm = ({handleCancel, handleFinish}) => {
  const [data, setData] = useState(INITIAL_DATA)
  function updateFields(fields){
    setData(prev => {
        return{...prev, ...fields}
    })
  }
  const {steps, currentStepIndex, step, isFirstStep, isLastStep, back, next} = useMultistepForm([
      <FileUploadSection />,
      <Details {...data} updateFields={updateFields}/>
  ],handleCancel)

  async function onSubmit(e){
    e.preventDefault()
    if (!isLastStep) return next()
    const token = localStorage.getItem('token');
    const shop_id = localStorage.getItem('shopId')
    try {
        // Create a scene
        const sceneResponse = await axios.post(`${API_BASE_URL}${SCENES_ENDPOINT}`, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log('Scene created:', sceneResponse.data);
        const scene_id = sceneResponse.data.id; // Assuming the response includes the scene ID

        // Link the scene to a shop
        await axios.post(`${API_BASE_URL}${SCENES_SHOP_ENDPOINT}`, {
            shop_id: shop_id,
            scene_id: scene_id
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log('Scene linked to the shop');
        handleFinish();
    } catch (error) {
        console.error('Error while sending data:', error);
    }
    handleFinish();
  }

  return (
    <div style= {{
      position: "relative",
      background: "white",
      border: "1px solid black",
      padding: "2rem",
      margin: "1rem",
      borderRadius: ".5rem",
      fontFamily: "Arial",
    }}>
      <form onSubmit={onSubmit}>
        <div
        style={{position: "absolute", top: ".5rem", right: ".5rem"}}>
          {currentStepIndex + 1} / {steps.length}
        </div>
        {step}
        <div style={{marginTop: "1rem", display:"flex", gap: ".5rem", justifyContent: "flex-end"}}>
          
          <button type="button" onClick={back}>
            {isFirstStep ? "Cancel" : "Back"}
          </button>
          <button type="submit">
            {isLastStep ? "Finish" : "Next"}
          </button>
        </div>
      </form>

    </div>
  );
};

export default SceneForm;