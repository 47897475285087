import React from 'react';

function FAQ() {
    return (

        <h1>FAQ Page</h1>

    )
}

export default FAQ;